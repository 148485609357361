import Sticky from 'sticky-js';

export function sidebarFaqA1() {
    const hooks = {
        heading: 'js-category-title',
        link: 'js-category-link',
    };

    const headings = document.querySelectorAll(`.${hooks.heading}`);

    // Init sticky functionality
    const sticky = new Sticky('.js-sticky');

    if (headings.length === 0) {
        return;
    }

    // Bold content links in scroll
    const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
            const id = entry.target.getAttribute('id');

            if (entry.intersectionRatio > 0) {
                document
                    .querySelector(`[href="#${id}"]`)
                    .classList.add('text-brand-color-1');
            } else {
                document
                    .querySelector(`[href="#${id}"]`)
                    .classList.remove('text-brand-color-1');
            }
        });
    });

    // Track all sections that have an `id` applied
    headings.forEach((heading) => {
        observer.observe(heading);
    });
}
