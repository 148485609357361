import AOS from 'aos';

/**
 * Initializes the accordion menu.
 */
function menuA1() {
    const selector = {
        heading: '.js-accordion_heading',
        headingTitle: '.js-heading-title',
        group: '.js-accordion_group',
        dropdown: '.js-accordion_dropdown',
        track: '.js-accordion_track',
        currentItem: '.current-menu-item span',
    };

    /**
     * Defines the active state classes.
     */
    const state = {
        groupActive: 'is-active',
        trackActive: 'rotate-180',
    };

    /**
     * Replaces accordion title if a current page exists in the menu.
     */
    const currentItem = document.querySelector(selector.currentItem);

    if (currentItem) {
        document.querySelector(selector.headingTitle).innerHTML =
            currentItem.innerHTML;
    }

    /**
     * Toggles the accordion on click.
     * @param {Event} e - The click event.
     */
    const toggleAccordion = (e) => {
        console.log('clicked');
        const group = e.target.closest(selector.group);
        const dropdown = group.querySelector(selector.dropdown);
        const track = group.querySelector(selector.track);

        group.classList.toggle(state.groupActive);
        track.classList.toggle(state.trackActive);
        $(dropdown).slideToggle(300); // jQuery is used here, hence the eslint-disable-next-line comment.

        // Refresh Animation on Scroll module Events
        setTimeout(() => {
            AOS.refresh();
        }, 300);
    };

    document.querySelectorAll(selector.heading).forEach((heading) => {
        heading.addEventListener('click', toggleAccordion);
    });
}

export default menuA1;
