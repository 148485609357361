import NumbersAnimation from '/src/global/js/animations/NumbersAnimation';

function statisticsA1() {
    // Retreive a list of numbers blocks
    const blocks = document.querySelectorAll('.js-statistics-block');
    // Initialize a new NumbersAnimation item for each of them
    blocks.forEach((block) => {
        new NumbersAnimation(block);
    });
}

export default statisticsA1;
