/**
 * Each instance of this class have a number animations for the passed selector elements
 */

import Helpers from '/src/global/js/helpers/helpers.js';

class NumbersAnimation {
    /**
     * Main block container
     * @type {HTMLElement}
     */
    container;

    /**
     * Whether the block was already viewed or not
     * @type {Boolean}
     */
    isViewed = false;

    /**
     * HTMLCollection of number blocks
     * @type {HTMLCollection}
     */
    numberBlocks;

    /**
     * Selector for inner number blocks
     * @type {String}
     */
    numberItemSelector = '.js-animated-number';

    /**
     * Initialize a new instance of the class
     * @param {HTMLElement} container
     * @param {String} numberItemSelector
     */
    constructor(container, numberItemSelector = null) {
        this.container = container;
        if (numberItemSelector) {
            this.numberItemSelector = numberItemSelector;
        }
        this.numberBlocks = this.container.querySelectorAll(
            this.numberItemSelector,
        );

        // On page load
        this.init();
        // On scroll event
        window.addEventListener('scroll', this.init.bind(this));
    }

    /**
     * Core functionality
     */
    init() {
        // Iterate over each numberBlock
        [...this.numberBlocks].forEach((numberBlock) => {
            if (
                // Check if the numberBlock is scrolled into view
                Helpers.isScrolledIntoView($(numberBlock)) &&
                numberBlock.innerText !== '' &&
                !numberBlock.dataset.viewed // Check if it's not already viewed
            ) {
                numberBlock.dataset.viewed = true; // Set a 'viewed' flag on the numberBlock

                const finalNumber = parseInt(
                    numberBlock.innerText.replace(/[,.]/g, ''),
                    10,
                );

                $(numberBlock)
                    .prop('Counter', 0)
                    .animate(
                        {
                            Counter: finalNumber,
                        },
                        {
                            duration: 5000,
                            easing: 'swing',
                            step: function (now) {
                                let newValue = Math.ceil(now);
                                if (finalNumber < 200) {
                                    newValue = Math.floor(newValue);
                                } else {
                                    newValue = Math.floor(newValue / 10) * 10;
                                }
                                $(this).text(
                                    Math.ceil(newValue).toLocaleString(),
                                );
                            },
                            complete: function (arg) {
                                $(this).text(finalNumber.toLocaleString());
                            },
                        },
                    );
            }
        });
    }
}

export default NumbersAnimation;
