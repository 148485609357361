/**
 * Hamburger
 *
 * Runs the functionality of clicking the hamburger icon in the top nav.
 */

/**
 * Toggle the hamburger menu and perform related actions.
 */
export function hamburger() {
    const selector = '.js-hamburger';
    const activeClass = 'is-active';
    const bodyActiveClass = 'h-scroll-lock nav-active';
    const headerActiveClass = 'o-header-1--hamburger-active';
    const navMenu = '.js-slide-out';
    const navMenuActiveClass = 'm-slide-out--active';
    const headerSearchButton = 'o-header__search-button';
    const searchBar = 'm-search-bar';

    /**
     * Event handler for hamburger click.
     * @param {Event} e - The click event.
     */
    const handleClick = (e) => {
        e.preventDefault();
        $(selector).toggleClass(activeClass);

        // Ensuring body cannot be scrolled up and down when the mobile nav is shown
        $('.js-body').toggleClass(bodyActiveClass);
        $('.js-header').toggleClass(headerActiveClass);

        // Makes slide-out appear
        $(navMenu).toggleClass(navMenuActiveClass);

        $(`.${headerSearchButton}`).toggleClass(`${headerSearchButton}--anim`);

        if ($(`.${searchBar}`).hasClass(`${searchBar}--active`)) {
            $(selector).parent().siblings(headerSearchButton).trigger('click');
        }
    };

    /**
     * Event handler for window resize.
     */
    const handleResize = () => {
        if ($(window).width() >= 1140) {
            if ($('.o-header-1__hamburger').hasClass('is-active')) {
                $('.o-header-1__hamburger').trigger('click');
            }
        }
    };

    $(selector).click(handleClick);
    $(window).on('resize', handleResize);
}
