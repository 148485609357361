import NumbersAnimation from '/src/global/js/animations/NumbersAnimation';

function statisticsB1() {
    // Retreive a list of numbers blocks
    const blocks = document.querySelectorAll('.js-statistics-block-b');
    // Initialize a new NumbersAnimation item for each of them
    blocks.forEach((block) => {
        new NumbersAnimation(block, '.js-animated-number-b');
    });
}

export default statisticsB1;
