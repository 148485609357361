// Import the 'mixitup' library
import mixitup from 'mixitup';

// Function to update styles for the last item
function updateLastItemStyle() {
    // Get all visible elements in the container
    let items = document.querySelectorAll(
        '.mix:not([style*="display: none;"])',
    );

    // Add Tailwind CSS classes for all items
    items.forEach((item) => {
        item.classList.add('border-b', 'border-solid', 'border-gray-300');
    });

    // Remove Tailwind CSS classes for the last item
    let lastItem = Array.from(items).slice(-1)[0];
    if (items.length > 0) {
        lastItem.classList.remove(
            'border-b',
            'border-solid',
            'border-gray-300',
        );
    }
}

// Function to handle media filtering
function filterMedia() {
    // Select the container element for media filtering
    let containerEl = document.querySelector('.js-media-filter');

    // Initialize mixitup with sorting
    let mixer = mixitup(containerEl, {
        load: {
            sort: 'published-date:desc',
        },
        callbacks: {
            // Callback function called after each mix (sorting or filtering)
            onMixEnd: function (state) {
                // Call the function to update styles for the last item
                updateLastItemStyle();
            },
        },
    });

    // Call the function on initial page load
    updateLastItemStyle();

    let sortButton = document.getElementById('sortButton');
    let arrowIcon = document.getElementById('arrowIcon');
    let sortOptions = document.getElementById('sortOptions');
    let controlElements = document.querySelectorAll('.control-btn');

    // Event listener for toggling the visibility of sort options
    if (sortButton) {
        sortButton.addEventListener('click', function () {
            sortOptions.classList.toggle('hidden');
            arrowIcon.classList.toggle('rotate-180');
        });
    }

    // Event listener to handle clicks outside the sort button and options
    document.addEventListener('click', function (event) {
        // Check if the click is inside the sort button or options
        let isClickInside =
            sortButton.contains(event.target) ||
            sortOptions.contains(event.target);

        // Iterate through control elements to check for clicks
        for (let i = 0; i < controlElements.length; i++) {
            // If a control element is clicked, update the sort button text and hide options
            if (controlElements[i].contains(event.target)) {
                document.getElementById('sortButtonText').textContent =
                    controlElements[i].textContent;

                sortOptions.classList.add('hidden');
                arrowIcon.classList.remove('rotate-180');
                return;
            }

            // Update isClickInside flag
            isClickInside =
                isClickInside || controlElements[i].contains(event.target);
        }

        // If the click is outside both the sort button and options, hide options
        if (!isClickInside) {
            sortOptions.classList.add('hidden');
            arrowIcon.classList.remove('rotate-180');
        }
    });
}

export default filterMedia;
