/**
 * This class facilitates the manipulation of YouTube embeds and embed links using the YouTube API.
 * It follows the Singleton pattern and dynamically adds the necessary script tag to the current page to include the YouTube API library.
 * Additionally, the class maintains a collection of loaded videos as parameters of the video class, enabling the creation of individual YouTube players for each video instance.
 */
class YoutubeEmbedVideos {
    /**
     * Single instance of the current class.
     * @type {YoutubeEmbedVideos}
     */
    static instance = null;

    /**
     * Loaded Youtube embed videos.
     * @type {YoutubeEmbedVideo[]}
     */
    videos = [];

    /**
     * YouTube script URL.
     * @type {string}
     */
    scriptPath = 'https://www.youtube.com/iframe_api';

    /**
     * Script tag ID value
     * @type {string}
     */
    loadScriptId;

    /**
     * Indicates whether the Youtube API script has been loaded or not.
     * @type {boolean}
     */
    scriptLoaded = false;

    constructor() {}

    /**
     * Returns a single instance of the class.
     * @returns {Promise<YoutubeEmbedVideos>}
     */
    static async getInstance() {
        if (YoutubeEmbedVideos.instance === null) {
            await YoutubeEmbedVideos.createInstance();
        }

        return YoutubeEmbedVideos.instance;
    }

    /**
     * Create a new instance of the class.
     * @returns {Promise<void>}
     */
    static async createInstance() {
        YoutubeEmbedVideos.instance = new YoutubeEmbedVideos();
        await YoutubeEmbedVideos.instance.init();
    }

    /**
     * Init Class.
     * @returns {Promise<void>}
     */
    async init() {
        await this.loadYoutubeScripts();
    }

    /**
     * Load Youtube API scripts to the current page.
     * @returns {Promise<unknown>}
     */
    async loadYoutubeScripts() {
        return new Promise((resolve, reject) => {
            if (this.scriptLoaded) {
                resolve();
                return;
            }

            const scriptTag = document.createElement('script');
            scriptTag.setAttribute('src', this.scriptPath);
            scriptTag.setAttribute('id', this.loadScriptId);
            scriptTag.onload = () => {
                this.scriptLoaded = true;
                resolve();
            };
            scriptTag.onerror = () => {
                this.scriptLoaded = false;
                reject(new Error('Failed to load Youtube API script'));
            };
            document.head.appendChild(scriptTag);
        });
    }

    /**
     * Script tag ID value.
     * @returns {string}
     */
    get loadScriptId() {
        return `script-youtube-embed-video`;
    }

    /**
     * Returns an instance of the YoutubeEmbedVideo class corresponding to the provided HTML element.
     * @param element
     * @returns {Promise<YoutubeEmbedVideo>}
     */
    async getVideo(element) {
        if (!element) {
            throw new Error('No HTML element is provided');
        }
        const videoId = element.id;
        if (!videoId) {
            throw new Error('Provided HTML element has no ID');
        }
        return new Promise((resolve, reject) => {
            const createVideoInstance = async () => {
                if (
                    !this.videos[videoId] ||
                    !(this.videos[videoId] instanceof YoutubeEmbedVideo)
                ) {
                    this.videos[videoId] =
                        await YoutubeEmbedVideo.createInstance(element);
                }
                resolve(this.videos[videoId]);
            };

            createVideoInstance().catch(reject);
        });
    }
}

/**
 * The class holds references to the YouTube player and YouTube iframe tag, among other elements.
 */
class YoutubeEmbedVideo {
    /**
     * Container of the video embed.
     * @type {HTMLElement}
     */
    container = null;

    /**
     * A singular, existing instance of the class associated with the provided HTML element.
     * @type {YoutubeEmbedVideo}
     */
    static instance = null;

    /**
     * HTML element container of the Youtube iframe.
     * @type {HTMLElement}
     */
    ytPlayerContainer = null;

    /**
     * Youtube Player instance.
     * @type {Player}
     */
    ytPlayer = null;

    /**
     * Iframe Width.
     * @type {number}
     */
    width;

    /**
     * Iframe height.
     * @type {number}
     */
    height;

    /**
     * Youtube video ID.
     * @type {string}
     */
    videoID;

    /**
     * Other parameters of Youtube Player.
     * @type {Object}
     */
    ytPlayerVars;

    /**
     * Indicates whether the YouTube player has been created or not.
     * @type {boolean}
     */
    ytPlayerLoaded = false;

    /**
     *
     * @param {HTMLElement} element
     */
    constructor(element) {
        if (!element) {
            throw new Error(
                'No HTML element is provided while trying to create a new instance of the YoutubeEmbedVideo class.',
            );
        }
        if (!element.dataset.videoId) {
            throw new Error(
                "No YouTube video ID is detected within the container's data parameters.",
            );
        }
        this.container = element;
    }

    /**
     * iFrame tag height attribute value.
     * @returns {number}
     */
    get height() {
        return this.container.dataset.height
            ? parseInt(this.container.dataset.height)
            : 360;
    }

    /**
     * iFrame tag width attribute value.
     * @returns {number}
     */
    get width() {
        return this.container.dataset.width
            ? parseInt(this.container.dataset.width)
            : 640;
    }

    /**
     * Youtube Video ID.
     * @returns {string}
     */
    get videoId() {
        return this.container.dataset.videoId;
    }

    /**
     * A subset of the parameters used for constructing the YouTube player.
     * @returns {{}}
     */
    get ytPlayerVars() {
        const srcQueryParams = {};
        srcQueryParams.autoplay = 0;
        srcQueryParams.controls = 1;
        srcQueryParams.playsinline = 1;
        srcQueryParams.host = 'https://www.youtube.com';
        srcQueryParams.origin = window.location.origin;

        return srcQueryParams;
    }

    /**
     * Create a new instance of the class.
     * @param {HTMLElement} element
     * @returns {Promise<YoutubeEmbedVideo>}
     */
    static async createInstance(element) {
        YoutubeEmbedVideo.instance = new YoutubeEmbedVideo(element);
        await YoutubeEmbedVideo.instance.init();

        return YoutubeEmbedVideo.instance;
    }

    /**
     * Initialize the class by creating iframe container, building Youtube player player etc
     * @returns {Promise<void>}
     */
    async init() {
        this.ytPlayerContainer = this.createYTPlayerElement();
        this.container.appendChild(this.ytPlayerContainer);
        this.ytPlayerContainer.style.display = 'block';
        this.ytPlayerContainer.style.width = '100%';
        this.ytPlayerContainer.style.height = '100%';

        this.ytPlayer = await this.buildPlayer();
    }

    /**
     * Build YouTube Player.
     * @returns {Promise<Player>>}
     */
    async buildPlayer() {
        return new Promise((resolve, reject) => {
            if (this.ytPlayerLoaded) {
                resolve(this.ytPlayer);
                return;
            }
            const playerOptions = {
                events: {
                    onError: (error) => {
                        reject(new Error(error));
                    },
                    onReady: (e) => {
                        resolve(e.target);
                        this.ytPlayerLoaded = true;
                    },
                    onStateChange: (obj) => {},
                },
                height: this.height,
                playerVars: this.ytPlayerVars,
                videoId: this.videoId,
                width: this.width,
            };
            window.YT.ready(() => {
                this.ytPlayer = new window.YT.Player(
                    this.ytPlayerContainer,
                    playerOptions,
                );
            });
        });
    }

    /**
     * Create HTML element for iframe tag container.
     * @returns {HTMLDivElement}
     */
    createYTPlayerElement() {
        return document.createElement('div');
    }

    /**
     * Play video.
     */
    play() {
        if (this.ytPlayer) {
            this.ytPlayer.playVideo();
        }
    }

    /**
     * Pause video.
     */
    pause() {
        if (this.ytPlayer) {
            this.ytPlayer.pauseVideo();
        }
    }
}

export { YoutubeEmbedVideos };
