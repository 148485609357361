function galleryA() {
    jQuery(document).ready(function ($) {
        if ($('.js-gallery-a').length > 0) {
            $('.js-gallery-a').lightGallery({
                selector: '.js-gallery-img',
                thumbnail: true,
                animateThumb: true,
                showThumbByDefault: true,
                mode: 'lg-slide',
            });
        }
    });
}

export default galleryA;
