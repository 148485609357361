export default function harnessWidget() {
    !(function (t, e) {
        var a = document.head,
            n = document.createElement('script');
        (n.type = 'text/javascript'),
            (n.src = 'https://widget.harnessapp.com/harness-widget-v2.js'),
            (n.onreadystatechange = e),
            (n.onload = e),
            a.appendChild(n);
    })(0, function () {
        window.HarnessWidget.init({ charity_id: '25ea164bb90b948ea25a' });
    });

    let jsElm = document.createElement('script');
    // set the type attribute
    jsElm.type = 'application/javascript';
    // DOM: Create the script element
    jsElm.src =
        // eslint-disable-next-line no-secrets/no-secrets
        'https://tag.simpli.fi/sifitag/54a0fe40-cebf-0138-339a-06abc14c0bc6?referer=https://ocredux.wpengine.com';
    // finally insert the element to the body element in order to load the script
    document.body.appendChild(jsElm);
}
